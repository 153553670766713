exports.linkResolver = function linkResolver(doc) {
  let $data;
  let $uid;
  
  doc._meta ? $data = doc._meta.type : $data = doc.type;
  doc._meta ? $uid = doc._meta.uid : $uid = doc.uid;

  if ($data === 'landing_page') {
    return '/' + $uid + '/';
  }
  if ($data === 'news_content') {
    return '/news-travel-tips/' + $uid + '/';
  }
  if ($data === 'inspiration') {
    return '/inspiration/' + $uid + '/';
  }
  if ($data === 'destination') {
    return '/inspiration/' + $uid + '/';
  }
  if ($data === 'content') {
    return '/' + $uid + '/';
  }
  if ($data === 'contact_form') {
    return '/' + $uid + '/';
  }
  if ($data === 'gallery') {
    return '/' + $uid + '/';
  }

  return '/';
}