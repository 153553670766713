module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-177087415-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"totaltrips","path":"/preview","previews":true,"accessToken":"MC5Ycko4ZXhBQUFDTUFsQWot.NB5jAu-_ve-_ve-_ve-_vUDvv73vv73vv70Pa3rvv73vv709ByUz77-977-977-9Ykvvv71CYl7vv70Z","omitPrismicScript":false,"pages":[{"type":"Landing_page","match":"/:uid","previewPath":"/landing-page/preview","component":"/opt/build/repo/src/components/04 Templates/landing-page.js"},{"type":"Landing_page","match":"/:uid","previewPath":"/news-travel-tips/preview","component":"/opt/build/repo/src/components/04 Templates/news-landing-page.js"},{"type":"News_content","match":"/news-travel-tips/:uid","previewPath":"/news-travel-tips/article/preview","component":"/opt/build/repo/src/components/05 Pages/newsPost.js"},{"type":"Destination","match":"/inspiration/:uid","previewPath":"/inspiration/preview","component":"/opt/build/repo/src/components/05 Pages/destination.js"},{"type":"Inspiration","sortBy":"meta_firstPublicationDate_ASC","match":"/inspiration/:uid","previewPath":"/inspiration/article/preview","component":"/opt/build/repo/src/components/05 Pages/inspirationPost.js"},{"type":"Content","match":"/:uid","previewPath":"/content/preview","component":"/opt/build/repo/src/components/05 Pages/contentPost.js"},{"type":"Gallery","match":"/:uid","previewPath":"/gallery/preview","component":"/opt/build/repo/src/components/05 Pages/gallery.js"},{"type":"Contact_form","match":"/:uid","previewPath":"/form/preview","component":"/opt/build/repo/src/components/05 Pages/contact.js"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TotalTrips.com","short_name":"TotalTrips.com","display":"standalone","background_color":"#fff","theme_color":"#333","start_url":"/","icon":"src/static/icon-512x512.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"05c1816dc92c48c9f82d93e8ccaa9409"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
